import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '@deepthink/thinkreservations-fonts/lib/source-serif-pro.css';
import '@deepthink/thinkreservations-fonts/lib/whitney.css';

import './css/reset.css';
import './css/base.css';
import './index.css';

import Header from '../Header';
import MobileHeader from '../MobileHeader';
import GetStartedPanel from '../GetStartedPanel';
import Footer from '../../ui-library/Footer';

export class App extends Component {
  static propTypes = {
    displayGettingStartedPanel: PropTypes.bool,
  };

  static defaultProps = {
    displayGettingStartedPanel: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openMenu() {
    this.setState({
      isOpen: true,
    });
  }

  closeMenu() {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { isOpen } = this.state;

    const { displayGettingStartedPanel } = this.props;

    return (
      <div className="component app">
        <Helmet htmlAttributes={{ lang: 'en' }}></Helmet>
        <Header />
        <MobileHeader
          isOpen={isOpen}
          onOpenMenu={this.openMenu}
          onCloseMenu={this.closeMenu}
        />
        {this.props.children}
        {displayGettingStartedPanel && <GetStartedPanel />}
        <Footer />
      </div>
    );
  }
}

export default App;
