import React, { Component } from 'react';

import Link from '../../core/Link';
import Navigation from '../Navigation';

import './index.css';

import thinkreservationsLogoImage from './images/TR-logo.svg';

export class Header extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <header className="component  header px-[36px]">
        <div className="flex justify-end gap-4 items-center py-1 ">
          <a
            id="log-in"
            className="text-[#51b0d7] pr-4 border-r-2 border-solid border-slate-200 font-semibold"
            href="https://manage.thinkreservations.com/"
          >
            Log In
          </a>
          <a className="phone" href="tel:8777364195">
            877-736-4195
          </a>
        </div>
        <hr className="-mx-[36px] border-slate-200" />
        <div className="flex items-center justify-between my-4">
          <Link to="/" className="header-logo-link">
            <img
              className="header-logo h-[24px]"
              src={thinkreservationsLogoImage}
              height="24"
              alt=""
            />
          </Link>

          <div className="flex items-center">
            <Navigation className="primary-navigation" />

            <ul className="secondary-navigation">
              <li>
                <Link id="request-a-demo" to="/request-a-demo">
                  Request a demo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
