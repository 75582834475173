import React from 'react';
import Link from '../../core/Link';
import thinkreservationsLogoImage from './images/TR-logo-white.svg';

/*
From Tailwind UI
*/
const navigation = {
  products: [
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/products/booking-engine"
    >
      Booking Engine
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/products/property-management-system"
    >
      Property Management System
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/products/channel-manager"
    >
      Channel Manager
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/products/integrations"
    >
      Integrations
    </Link>,
    <Link className="text-base text-gray-50 hover:text-white" to="/pricing">
      Pricing
    </Link>,
  ],
  company: [
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/company/our-team"
    >
      Our Team
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/company/careers"
    >
      Careers
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/company/contact-us"
    >
      Contact Us
    </Link>,
  ],
  resources: [
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/resources/articles"
    >
      Articles
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/resources/webinars"
    >
      Webinars
    </Link>,
    <Link
      className="text-base text-gray-50 hover:text-white"
      to="/resources/product-updates"
    >
      Product Updates
    </Link>,
    <a
      className="text-base text-gray-50 hover:text-white"
      href="http://support.thinkreservations.com"
    >
      Support
    </a>,
  ],

  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/ThinkReservations/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-[#3f97ca]" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="flex flex-wrap justify-between xl:col-span-3">
            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                Products
              </h3>
              <ul role="list" className="mt-4 space-y-4 list-none">
                {navigation.products.map((item, index) => (
                  <li key={'product ' + index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="">
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                Resources
              </h3>
              <ul role="list" className="mt-4 space-y-4 list-none">
                {navigation.resources.map((item, index) => (
                  <li key={'resources ' + index}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                Company
              </h3>
              <ul role="list" className="mt-4 space-y-4 list-none">
                {navigation.company.map((item, index) => (
                  <li key={'company ' + index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 hidden xl:mt-0">
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-50">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                className="text-white hover:text-gray-50"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div
            className="mt-8 text-base text-white md:mt-0 md:order-1"
            id="footer-copyright"
          >
            <Link to="/">
              <img
                className="h-[28px] my-4"
                id="footer-logo"
                src={thinkreservationsLogoImage}
                height="48"
                alt=""
              />
            </Link>
            &copy; ThinkReservations &mdash; all rights reserved.{' '}
            <span className="policy-links">
              <Link to="/terms">Terms &amp; Conditions</Link> |{' '}
              <Link to="/privacy">Privacy Policy</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
