import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../core/Link';
import MobileNavigation from '../MobileNavigation';

import './index.css';

import thinkreservationsLogoImage from './images/TR-logo.svg';

import menuOpenImage from './images/mobile_menu_open.svg';
import menuCloseImage from './images/mobile_menu_close.svg';

export class MobileHeader extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenMenu: PropTypes.func.isRequired,
    onCloseMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { isOpen, onOpenMenu, onCloseMenu } = this.props;

    if (isOpen) {
      onCloseMenu();
    } else {
      onOpenMenu();
    }
  }

  render() {
    const { isOpen } = this.props;

    return (
      <header className="component mobile-header">
        <div className="logo-wrapper">
          <Link to="/">
            <img
              className="logo h-[24px]"
              src={thinkreservationsLogoImage}
              height="24"
              alt=""
            />
          </Link>
        </div>

        <button
          className="menu-toggle"
          aria-label={isOpen ? 'Close main menu' : 'Open main menu'}
          onClick={this.toggleMenu}
        >
          <img src={isOpen ? menuCloseImage : menuOpenImage} alt="" />
          <span className="sr-only">
            {isOpen ? 'Close main menu icon' : 'Open main menu icon'}
          </span>
        </button>

        {isOpen && (
          <div className="mobile-header-navigation">
            <MobileNavigation />
          </div>
        )}
      </header>
    );
  }
}

export default MobileHeader;
