import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../core/Link';

import solutionsNavigation from '../../pages/solutions/navigation';

import './index.css';

export class Navigation extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { id, className } = this.props;

    return (
      <ul className={`component navigation ${className}`} id={id}>
        <li className="tier-one products">
          <span className="tier-one products">Products</span>
          <ul>
            <li className="tier-two">
              <Link to="/products/booking-engine">Booking Engine</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/property-management-system">
                Property Management System
              </Link>
            </li>
            <li className="tier-two">
              <Link to="/products/channel-manager">Channel Manager</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/payment-processing">Payment Processing</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/integrations">Integrations</Link>
            </li>
          </ul>
        </li>
        <li className="tier-one solutions">
          <span className="tier-one">Solutions</span>
          <ul>
            {solutionsNavigation.map(({ anchor, href }) => (
              <li key={href} className="tier-two">
                <Link to={href}>{anchor}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="tier-one resources">
          <span className="tier-one">Resources</span>
          <ul>
            <li className="tier-two">
              <Link to="/resources/articles">Articles</Link>
            </li>
            <li className="tier-two">
              <Link to="/resources/webinars">Webinars</Link>
            </li>
            <li className="tier-two">
              <Link to="/resources/product-updates">Product Updates</Link>
            </li>
            <li className="tier-two">
              <a href="http://support.thinkreservations.com">Support</a>
            </li>
          </ul>
        </li>
        <li className="tier-one pricing">
          <Link className="tier-one" to="/pricing">
            Pricing
          </Link>
        </li>
        <li className="tier-one company">
          <span className="tier-one">Company</span>
          <ul>
            <li className="tier-two">
              <Link to="/company/our-team">Our Team</Link>
            </li>
            <li className="tier-two">
              <Link to="/company/careers">Careers</Link>
            </li>
          </ul>
        </li>
        <li className="tier-one">
          <a className="tier-one" href="/company/contact-us">
            Contact Us
          </a>
        </li>
      </ul>
    );
  }
}

export default Navigation;
